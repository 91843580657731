// @ts-ignore
import {Entity} from 'aframe-react';
import React from "react";

export interface ControllerInstructionsProps {
    hideFloorPlanButton: boolean;
    hideRoomButton: boolean;
}

// Images that show what each button in the right controller does.
export function ControllerInstructions(props: ControllerInstructionsProps) {
    const { hideRoomButton, hideFloorPlanButton } = props;

    return <>
        {!hideRoomButton && <Entity position="-0.03 0.01 -0.04" mixin="controllerinstruction" class="controllerinstruction">
            <Entity primitive="a-image" src="#roomicon" width="0.016" height="0.016" position="0 0 0.001" material="opacity: 1"></Entity>
            <Entity geometry="primitive: circle; radius: 0.014; segments: 64;" position="0 0 -0.001"
                    material="flatShading: true; color: blue; side: double; opacity: 1;" />
            <Entity line="start: 0 0 0; end: 0.024 -0.014 -0.02; color: blue" />
        </Entity>}

        {!hideFloorPlanButton && <Entity position="-0.025 0.022 -0.01" mixin="controllerinstruction" class="controllerinstruction">
            <Entity primitive="a-image" src="#floorplanIcon" width="0.016" height="0.018" position="0 0 0.001" material="opacity: 1"></Entity>
            <Entity geometry="primitive: circle; radius: 0.014; segments: 64;" position="0 0 -0.001"
                    material="flatShading: true; color: blue; side: double; opacity: 1;" />
            <Entity line="start: 0 0 0; end: 0.02 0.01 -0.01; color: blue" />
        </Entity>}

        <Entity position="0.036 0.022 -0.01" mixin="controllerinstruction" class="controllerinstruction">
            <Entity geometry="primitive: plane; height: 0.026; width: 0.002;" position="0 0 0.001"
                    material="flatShading: true; color: blue; side: double; opacity: 1;" rotation="0 0 45"></Entity>
            <Entity primitive="a-image" src="#vrimage" width="0.02" height="0.018" position="0 0 0.0005" material="opacity: 1"></Entity>
            <Entity geometry="primitive: circle; radius: 0.014; segments: 64;" position="0 0 -0.001"
                    material="flatShading: true; color: blue; side: double; opacity: 1;" />
            <Entity line="start: 0 0 0; end: -0.022 0.008 -0.01; color: blue" />
        </Entity>
    </>
}