import {Box, Stack, useMediaQuery, useTheme} from "@mui/material";
import {Floorplan, RoomImage} from "../../../api/apiClient";
import {useState} from "react";
import {BrowserMenu} from "./BrowserMenu";
import {BrowserNavigationTools} from "./BrowserNavigationTools";

export interface BrowserUIProps {
    floorPlans: Floorplan[];
    rooms: RoomImage[];
    currentPanorama: string;
    changePanorama: (panorama: string) => void;
}


export function BrowserUI(props: BrowserUIProps) {
    const {currentPanorama, changePanorama, floorPlans, rooms} = props;

    const theme = useTheme();
    const isSmallScreen = useMediaQuery(theme.breakpoints.down('sm'));

    const [showMinimap, setShowMinimap] = useState<boolean>(!isSmallScreen && floorPlans.length > 0);
    const [showRoomMenu, setShowRoomMenu] = useState<boolean>(false);
    const [isFullScreen, setIsFullScreen] = useState<boolean>(false);


    const switchPanorama = (panorama: string) => {
        changePanorama(panorama);
        if (isSmallScreen) {
            setShowMinimap(false);
        }
        setShowRoomMenu(false);
    }

   return <div style={{userSelect: "none", padding:10}}>
       {!isSmallScreen && <Stack direction={"row"}
                                 justifyContent={showRoomMenu || showMinimap ? "space-between" : "flex-end"}
                                 alignItems={"flex-start"}
                                 spacing={2}
                                 sx={{width: "100%", height: "100%"}}>

               <BrowserNavigationTools currentPanorama={currentPanorama} showRoomMenu={showRoomMenu} showMinimap={showMinimap}
                                       rooms={rooms} floorPlans={floorPlans} onHideMinimap={()=> setShowMinimap(false)}
                                       changePanorama={switchPanorama} />


               {/*Vertical menu for horizontal mobile and desktop*/}
               {!isSmallScreen && <Stack spacing={3} alignItems={"center"} >
                       <BrowserMenu isFullScreen={isFullScreen} showRoomMenu={showRoomMenu} showMinimap={showMinimap}
                                    disableMinimap={floorPlans.length === 0} disableRoomMenu={rooms.length === 0}
                                    setIsFullScreen={setIsFullScreen} setShowRoomMenu={setShowRoomMenu} setShowMinimap={setShowMinimap} />
                   </Stack>}
        </Stack>}


       {/*Horizontal menu for vertical mobile*/}
       {isSmallScreen && <>
           <Stack spacing={2} direction={"row"} sx={{p: 3, paddingBottom: 0}} justifyContent={"flex-end"}>
               <BrowserMenu isFullScreen={isFullScreen} showRoomMenu={showRoomMenu} showMinimap={showMinimap}
                            disableMinimap={floorPlans.length === 0} disableRoomMenu={rooms.length === 0}
                            setIsFullScreen={setIsFullScreen} setShowRoomMenu={setShowRoomMenu} setShowMinimap={setShowMinimap} />
           </Stack>

           <Box width={"100%"} textAlign={"center"}>
               <BrowserNavigationTools currentPanorama={currentPanorama} showRoomMenu={showRoomMenu} showMinimap={showMinimap}
                                       rooms={rooms} floorPlans={floorPlans}
                                       changePanorama={switchPanorama} onHideMinimap={()=> setShowMinimap(false)} />
           </Box>
       </>

       }
   </div>
}