import {BrowserMenuButton} from "./BrowserMenuButton";
import FullscreenExitIcon from "@mui/icons-material/FullscreenExit";
import FullscreenIcon from "@mui/icons-material/Fullscreen";
import CameraIndoorIcon from "@mui/icons-material/CameraIndoor";
import LayersClearIcon from "@mui/icons-material/LayersClear";
import LayersIcon from "@mui/icons-material/Layers";
import MenuIcon from '@mui/icons-material/Menu';
import CloseIcon from '@mui/icons-material/Close';
import {useMediaQuery, useTheme} from "@mui/material";
import SvgIcon from "@mui/material/SvgIcon";
import { ReactComponent as CameraIndoorExitSvg} from "../../../images/ui/camera_indoor_exit.svg"
import {useState} from "react";
import "../../../animations/menuToggle.css"

function openFullscreen() {
    var elem = document.documentElement;

    if (elem.requestFullscreen) {
        elem.requestFullscreen();
    } else {
        // @ts-ignore
        if (elem.webkitRequestFullscreen) { /* Safari */
            // @ts-ignore
            elem.webkitRequestFullscreen();
            // @ts-ignore
        } else if (elem.msRequestFullscreen) { /* IE11 */
            // @ts-ignore
            elem.msRequestFullscreen();
        }
    }
}

function closeFullscreen() {
    if (document.exitFullscreen) {
        document.exitFullscreen();
        // @ts-ignore
    } else if (document.webkitExitFullscreen) { /* Safari */
        // @ts-ignore
        document.webkitExitFullscreen();
        // @ts-ignore
    } else if (document.msExitFullscreen) { /* IE11 */
        // @ts-ignore
        document.msExitFullscreen();
    }
}

export interface BrowserMenuProps {
    isFullScreen: boolean;
    showRoomMenu: boolean;
    showMinimap: boolean;
    setIsFullScreen: (isFullScreen: boolean) => void;
    setShowRoomMenu: (showRoomMenu: boolean) => void;
    setShowMinimap: (showMinimap: boolean) => void;
    disableMinimap: boolean;
    disableRoomMenu: boolean;
}

export function BrowserMenu(props: BrowserMenuProps) {
    const { showRoomMenu, setShowRoomMenu, setShowMinimap, showMinimap, setIsFullScreen, isFullScreen, disableRoomMenu, disableMinimap } = props;

    const theme = useTheme();
    const isSmallScreen = useMediaQuery(theme.breakpoints.down('sm'));

    const [showFullMenu, setShowFullMenu] = useState(!isSmallScreen);

    // @ts-ignore Is VR supported on the environment
    const canEnterVR = AFRAME.utils.device.checkVRSupport();

    const CameraIndoorExitIcon = () => {
        return <SvgIcon fontSize={"large"}>
            <CameraIndoorExitSvg />
        </SvgIcon>
    }

    const VRModeIcon = () => <SvgIcon fontSize={"large"} fill={"currentColor"}>
        <svg version="1.1" id="Icons" xmlns="http://www.w3.org/2000/svg"
             xmlnsXlink="http://www.w3.org/1999/xlink" viewBox="1 0 30 30"
             xmlSpace="preserve">
            <mask id="cutout">
                <rect width="100%" height="100%" fill="white"/>

                <circle cx="9" cy="16" r="3" fill="black"/>
                <circle cx="23" cy="16" r="3" fill="black"/>
            </mask>

            <g id="SVGRepo_iconCarrier">
                <path mask="url(#cutout)"
                      d="M3,11v10c0,1.1,0.9,2,2,2h6.2c0.5,0,1-0.2,1.4-0.6l2-2c0.8-0.8,2-0.8,2.8,0l2,2c0.4,0.4,0.9,0.6,1.4,0.6H27 c1.1,0,2-0.9,2-2V11c0-1.1-0.9-2-2-2H5C3.9,9,3,9.9,3,11z"></path>
                <circle cx="9" cy="16" r="3"  fill={"none"}></circle>
                <circle cx="23" cy="16" r="3" fill={"none"}></circle>
            </g>
        </svg>
    </SvgIcon>

    return <>
        {showFullMenu && <>
            <BrowserMenuButton tooltip={isFullScreen ? "Exit fullscreen" : "Open fullscreen"}
                              className={"hidden-menu-item"}
                              onClick={() => {
                                  if (isFullScreen) {
                                      setIsFullScreen(false);
                                      closeFullscreen();
                                  } else {
                                      setIsFullScreen(true);
                                      openFullscreen();
                                  }
                              }}
                              icon={isFullScreen ? <FullscreenExitIcon/> : <FullscreenIcon/>}/>

            {!disableRoomMenu && <BrowserMenuButton tooltip={showRoomMenu ? "Close room menu" : "Open room menu"}
                               className={"hidden-menu-item"}
                               onClick={() => {
                                   setShowRoomMenu(!showRoomMenu);
                                   if (isSmallScreen) {
                                       setShowMinimap(false);
                                   }
                               }}
                               icon={showRoomMenu ? <CameraIndoorExitIcon /> : <CameraIndoorIcon/>}/>}


            {!disableMinimap && <BrowserMenuButton tooltip={showMinimap ? "Close minimap" : "Open minimap"}
                               className={"hidden-menu-item"}
                               onClick={() => {
                                   setShowMinimap(!showMinimap);
                                   if (isSmallScreen) {
                                       setShowRoomMenu(false);
                                   }
                               }}
                               icon={showMinimap ? <LayersClearIcon/> : <LayersIcon/>}/>}

            {canEnterVR && <BrowserMenuButton
                               id={"enterVRButton"}
                               className={"hidden-menu-item"}
                               icon={<VRModeIcon />}/>}
        </>}

        {isSmallScreen && <BrowserMenuButton tooltip={""} onClick={() =>
                                {
                                    if (showFullMenu) {
                                        setShowRoomMenu(false);
                                        setShowMinimap(false);
                                    }

                                    setShowFullMenu(curr => !curr);
                                }}
                                icon={showFullMenu ? <CloseIcon/> : <MenuIcon/>}/>}
    </>
}