import {RoomImage} from "../../../api/apiClient";
import {ImageListItem, ImageListItemBar, Skeleton, styled} from "@mui/material";
import {useState} from "react";

export interface BrowserRoomImageProps {
    room: RoomImage;
    isCurrent: boolean;
    onClick: (panorama: string) => void;
}

export function BrowserRoomImage(props: BrowserRoomImageProps) {
    const { onClick, room, isCurrent } = props;

    const [isImageLoaded, setIsImageLoaded] = useState(false);

    const ImageListItemWithStyle = styled(ImageListItem)(({ theme }) => ({
        "&:hover": {
            cursor: "pointer",
            filter: "brightness(0.9)",
        },
        transition: "filter 0.3s ease",
        border: isCurrent ? `solid 4px ${theme.palette.primary.main}` : "",
    }));

    const RoomListItem = () => <ImageListItemWithStyle key={"room-image-" + room.targetPanorama} onClick={() => onClick(room.targetPanorama)}>
        <img src={room.image} alt={room.imageHeader} loading={"lazy"} onLoad={() => setIsImageLoaded(true)} />
        <ImageListItemBar position={"bottom"} title={room.imageHeader} />
    </ImageListItemWithStyle>;

    return <>{!isImageLoaded ? <Skeleton variant={"rectangular"} width={"100%"} height={"100%"}><RoomListItem /></Skeleton> : <RoomListItem />}</>
}