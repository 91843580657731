import {THREE} from "aframe";
import {buildFloorPlanData} from "./floorPlanDataCalculator";

export interface PanoramaTour {
    header: string;
    panoramas: Panorama[];
    floorPlans: Floorplan[];
    rooms: RoomImage[];
    usesPanoramasAsRoomImages: boolean;
}

export interface Panorama {
    image: string;
    name: string;
    position: number[];
    cameraRotation: number;
    hotspots: LocationChangeSpots[];
}

export interface LocationChangeSpots {
    targetPanorama: string;
    position: THREE.Vector3;
    type: "spot"|"arrow";
    arrowDirection?: "down"|"up"|"upright"|"upleft"|"downright"|"downleft";
}

export interface Floorplan {
    image: string;
    name: string;
    imageHeader: string;
    imageWidth: number;
    imageHeight: number;
    hotspots: FloorplanHotspot[];
}

export interface FloorplanHotspot {
    x: number;
    y: number;
    targetPanorama: string;
    targetCameraRotation: number;
}

export interface RoomImage {
    image: string;
    assetId: string;
    imageHeader: string;
    targetPanorama: string;
    targetCameraRotation: number;
}

interface UnrealData {
    ViewpointGroup: UnrealViewPoints;
    Hotspots: UnrealHotSpots[];
    FloorPlans: UnrealFloorPlan[];
}

interface UnrealViewPoints {
    Name: string;
    Viewpoints: UnrealViewPoint[];
}
export interface UnrealViewPoint {
    Name: string;
    DisplayName: string;
    Location: UnrealVector;
    LookAtDirection: UnrealVector;
    Floor: string;
}

interface UnrealHotSpots {
    Source: string;
    Target: string;
    Offset: UnrealVector;
    Type: string;
    Direction: string;
    Icon: string;
}

export interface UnrealFloorPlan {
    Name: string;
    ViewCenter: Omit<UnrealVector, "Z">;
    ViewWidth: number;
}

interface UnrealVector {
    X:number;
    Y:number;
    Z:number;
}

interface TourMetadata {
    Name: string;
    ViewPointGroup: string;
    ImageExtension: string;
    PanoramaImageExtension?: string;
    FloorPlanImageExtension?: string;
    RoomImageExtension?: string;
    FloorPlanImageFolder?: string;
    RoomImageFolder?: string;
}

export class ApiClient {
    constructor(private readonly contentUrl: string) {
    }

    async getTourMetadata(): Promise<TourMetadata | undefined> {
        const metadata = await fetch(this.contentUrl + "/assets/tour.json");

        if (metadata.ok) {
            return await metadata.json() as TourMetadata;
        }
        return undefined;
    }

    async getPanoramaTour(): Promise<PanoramaTour | undefined> {

        let returnTour: PanoramaTour | undefined = undefined;

        const tourMetadata = await this.getTourMetadata();

        if (!tourMetadata) {
            alert("Tour configuration not found");
            return undefined;
        }

        //Fetch configuration
        await fetch(`${this.contentUrl}/assets/${tourMetadata.ViewPointGroup}.json`).then(async (response) =>
            {
                const responseJson = await response.json().catch(() => alert("VR Tour configuration is badly formed"));
                const isUnreal = (val: UnrealData): val is UnrealData => !!val?.ViewpointGroup;

                if (isUnreal(responseJson)) {

                    const floorPlans = await Promise.all(responseJson.FloorPlans.map(async (fp) =>
                        await buildFloorPlanData(fp, responseJson.ViewpointGroup.Viewpoints, this.contentUrl,
                            tourMetadata.FloorPlanImageFolder, tourMetadata.FloorPlanImageExtension ?? tourMetadata.ImageExtension)))

                    const vectorToArray = (vector: UnrealVector) =>
                        [vector.X / 100, vector.Z / 100, vector.Y / 100];

                    const panoramas : Panorama[] = responseJson.ViewpointGroup.Viewpoints.map((vp) => {

                        const panoramaHotSpots = responseJson.Hotspots.filter((hs) => hs.Source === vp.Name);

                        return {
                            image: `${this.contentUrl}/${vp.Name}.${tourMetadata.PanoramaImageExtension ?? tourMetadata.ImageExtension}`,
                            name: vp.Name.replace(" ", "_"),
                            position: vectorToArray(vp.Location),
                            cameraRotation: vp.LookAtDirection.X,
                            hotspots: panoramaHotSpots.map((hs) =>
                            {
                                const spotPos =
                                    {x: hs.Offset.X / 100, y: hs.Offset.Z / 100,z: hs.Offset.Y / 100};

                                return {
                                    type: "spot",
                                    position: new THREE.Vector3(-spotPos.x * 1.3, spotPos.y * 1.3, -spotPos.z * 1.3),
                                    targetPanorama: hs.Target.replace(" ", "_")
                                }
                            })
                        }
                    });

                    returnTour =  {
                        panoramas: panoramas,
                        header: responseJson.ViewpointGroup.Name,
                        usesPanoramasAsRoomImages: !tourMetadata.RoomImageFolder,
                        rooms: responseJson.ViewpointGroup.Viewpoints.filter((vp) => vp.DisplayName).map((vp) => {return{
                            assetId: (tourMetadata.RoomImageFolder ? "r-" : "") + vp.Name.replace(" ", "_"),
                            image: `${this.contentUrl}/${tourMetadata.RoomImageFolder ? tourMetadata.RoomImageFolder + "/" : ""}${vp.Name}.${tourMetadata.RoomImageExtension ?? tourMetadata.ImageExtension}`, // For now, the rooms should use panorama images.
                            targetPanorama: vp.Name.replace(" ", "_"),
                            imageHeader: vp.DisplayName,
                            targetCameraRotation: vp.LookAtDirection.X
                        }}),
                        floorPlans: floorPlans
                    };
                }
                else {
                    // THIS IS JUST FOR THE DEMO WELLS MODEL:
                    // Form the image URLs.
                    responseJson.panoramas.forEach((panorama: Panorama) => panorama.image = this.contentUrl + "/" + panorama.image);

                    responseJson.panoramas.forEach((panorama: Panorama) => panorama.hotspots.forEach((hs) =>
                        // @ts-ignore
                        hs.position = hs.type === "spot" ? new THREE.Vector3(hs.position[0] / 8, hs.position[1] / 8, hs.position[2] / 8) :
                        // @ts-ignore
                        new THREE.Vector3(hs.position[0], hs.position[1], hs.position[2])
                    ));
                    responseJson.floorPlans.forEach((floor: Floorplan) => floor.image = this.contentUrl + "/floorplans/" + floor.image);
                    responseJson.rooms.forEach((room: RoomImage) => room.image = `${this.contentUrl}/rooms/${room.image}`);

                    responseJson.usesPanoramasAsRoomImages = false;

                    returnTour = responseJson;
                }
            }
        ).catch((error) => alert("Failed to fetch tour configuration"));

        return returnTour;
    }



}