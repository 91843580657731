// @ts-ignore
import {Entity} from 'aframe-react';
import React, {useEffect, useRef, useState} from "react";
import {Panorama} from "../../api/apiClient";
import {launchPanoramaChangeAnimations} from "../common";
import {THREE} from "aframe";
import anime from "animejs";

export interface CameraRigProps {
    children?: React.ReactNode;
    vrController: React.ReactElement;
    panoramas: Panorama[];
    changePanorama: (newPanorama: string) => void;
    currentPanorama: string;
    isVrMode: boolean;
    vrMinimapVisible: boolean;
}

export function CameraRig(props: CameraRigProps) {
    const {children, panoramas, changePanorama, currentPanorama,
        vrController, isVrMode, vrMinimapVisible} = props;

    const cameraRigRef = useRef<Entity>(null);

    const [tourAnimation, setTourAnimation] = useState<anime.AnimeInstance | undefined>(undefined);
    const [runTour, setRunTour] = useState<boolean>(false);

    useEffect(() => {
        // When panorama has changed, restart the tour animation if tour should be running.
        if (runTour) {

            const assets = panoramas.map((panorama) => panorama.name);
            const rotations = panoramas.map((panorama) => panorama.cameraRotation);

            // Find out what position does the image have in the image list.
            let currentImageIndex = assets.findIndex((id: string) => id === currentPanorama);
            const rig = cameraRigRef.current;

            // Get rotation in degrees and convert to radians.
            const startRotation = rotations[currentImageIndex] ? THREE.MathUtils.degToRad(rotations[currentImageIndex]) : 0;

            setTourAnimation(anime({
                targets: rig.el.object3D.rotation,
                y: Math.PI * 2 + startRotation,
                duration: 50000,
                easing: 'easeInOutQuad',
                loop: false,
                autoplay: true,
                complete: handleTourLoopComplete
            }));
        }
    }, [currentPanorama, runTour]);

    const handleTourLoopComplete = () => {

        // After every loop we need to change the panorama image to another.

        // Find out what position does the image have in the image list.
        const currentPanoramaIndex = panoramas.findIndex((p) => p.name === currentPanorama);
        let nextPanoramaIndex: number;

        // If image is the last image, start from the beginning.
        if (currentPanoramaIndex === panoramas.length - 1) {
            nextPanoramaIndex = -1;
        }
        else {
            nextPanoramaIndex = currentPanoramaIndex + 1;
        }

        const nextPanorama = panoramas[nextPanoramaIndex];

        // Change panorama image to the next one.
        launchPanoramaChangeAnimations(undefined, nextPanorama.cameraRotation);

        // Change panorama
        setTimeout(() => {
            changePanorama(nextPanorama.name);
        }, 700);
    };


    // The a-camera has lot of functionality built in, it's better to put our functionalities to a parent "camera rig" entity.
    return <Entity id="cameraRig" camerazoom
                    position="0 0 0"
                    ref={cameraRigRef}
                    events={{
                        startTour: function (event: CustomEvent) {
                            setRunTour(true);
                        },
                        pauseTour: function () {
                            if (tourAnimation) {
                                // Pause the animation
                                tourAnimation.pause();

                                // Change the camera to use look-controls so that the user can move the camera with the mouse.
                                const camera = document.querySelector("#camera");
                                AFRAME.utils.entity.setComponentProperty(camera, "look-controls.enabled", true);
                            }

                            setRunTour(false);
                        },
                        zoomTowards: function (event: CustomEvent) {

                            let zoomerPosition = event.detail.zoomTo;
                            let animX = zoomerPosition.x > 0 ? zoomerPosition.x + 20 : zoomerPosition.x - 20;
                            let animZ = zoomerPosition.z > 0 ? zoomerPosition.z + 20 : zoomerPosition.z - 20;

                            const animPosition = animX + " 0 " + animZ;
                            const rig = cameraRigRef.current.el;

                            anime({
                                targets: rig,
                                position: ["0 0 0", animPosition],
                                duration: 700,
                                easing: 'easeInSine',
                                complete: function () {
                                    anime({
                                        targets: rig,
                                        position: [animPosition, "0 0 0"],
                                        duration: 1,
                                        easing: 'easeInSine'
                                    });
                                }
                            });
                        }
                    }}
    >
        {/* Camera entity specifies the way we view the scene. Children of the camera entity will move with the camera. */}
        <Entity primitive="a-camera" rotationreader={{isVrMode: isVrMode, vrMinimapVisible: vrMinimapVisible}} id="camera" fov="70" wasd-controls="enabled: false;"
                look-controls="enabled: false; reverseMouseDrag: true;" position="0 0 0">
            {children}
        </Entity>
        {vrController}
        <Entity laser-controls="hand: right" raycaster="objects: .raycastable; far: 100; lineColor: blue;" />
        <Entity laser-controls="hand: left" raycaster="objects: .raycastable; far: 100; lineColor: blue;" />
    </Entity>
}