import {BrowserMinimap} from "./BrowserMinimap";
import {BrowserRoomMenu} from "./BrowserRoomMenu";
import {BrowserUIProps} from "./BrowserUI";

export interface BrowserNavigationToolsProps extends BrowserUIProps {
    showMinimap: boolean;
    showRoomMenu: boolean;
    onHideMinimap: () => void;
}

export function BrowserNavigationTools(props: BrowserNavigationToolsProps) {
    const { currentPanorama, changePanorama, floorPlans,
        showMinimap, showRoomMenu, rooms, onHideMinimap } = props;

    return <>
        {/*Minimap*/}
        {showMinimap && <BrowserMinimap floorPlans={floorPlans} currentPanorama={currentPanorama}
                                          changePanorama={changePanorama}
                                          hideMinimap={onHideMinimap}/>}


        {/*Room menu*/}

        {showRoomMenu && <BrowserRoomMenu rooms={rooms} currentPanorama={currentPanorama} changePanorama={changePanorama}/>}
    </>
}