// @ts-ignore
import {Entity} from 'aframe-react';
import {RoomImage} from "../../../api/apiClient";
import {launchPanoramaChangeAnimations} from "../../common";


export interface RoomImageMenuProps {
    roomImages: RoomImage[];
    itemsPerRow: number;
    onItemClick: (clickedPanorama: string) => void;
}

export function RoomImageMenu(props: RoomImageMenuProps) {
    const { roomImages, itemsPerRow, onItemClick} = props;

    return <Entity id="roomImageMenu" imagemenulayout={`align: center; margin: 0.06; rowHeight: 0.25; itemsPerRow: ${itemsPerRow}`}>
        { roomImages.map((roomImage: RoomImage) => {
            return <Entity id={"menu-" + roomImage.assetId} key={"menu-" + roomImage.assetId}
                           mixin="imagemenu_frame"
                           geometry="height: 0.215; width: 0.315"
                           events={{click: () => {
                                   launchPanoramaChangeAnimations(undefined, roomImage.targetCameraRotation);
                                   setTimeout(() => {
                                       onItemClick(roomImage.targetPanorama);
                                   }, 700);
                               }}}
                           class="raycastable imageMenuItem">

                <Entity primitive="a-text" value={roomImage.imageHeader} color="black" width="0.9" position="0 0.13 0.001" align="center" opacity="1"
                        id={"rmheader-" + roomImage.assetId} key={"rmheader-" + roomImage.assetId} />

                <Entity primitive="a-image" src={"#" + roomImage.assetId} id={"roomimage-" + roomImage.assetId} key={"roomimage-" + roomImage.assetId}
                        mixin="imagemenu_image"
                        height="0.2" width="0.3" />
            </Entity>
        })}
    </Entity>
}