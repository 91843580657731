// @ts-ignore
import {Entity} from 'aframe-react';
import {Floorplan} from "../../../api/apiClient";
import {launchPanoramaChangeAnimations} from "../../common";
import {THREE} from "aframe";
import {useEffect} from "react";
require('aframe');

export interface MinimapProps {
    floorPlans: Floorplan[];
    currentPanorama: string;
    onSelectedFloorChanged: (newFloorIndex: number) => void;
    selectedFloorIndex: number;
    onHotSpotClick: (newPanorama: string) => void;
    onClose?: () => void;
    hideCloseButton?: boolean;
    mounted: () => void;
}

export function Minimap(props: MinimapProps) {
    const {currentPanorama, onHotSpotClick, floorPlans, onClose,
        hideCloseButton, selectedFloorIndex, onSelectedFloorChanged, mounted} = props;

    useEffect(() => {
        mounted();
    }, []);

    if (selectedFloorIndex < 0) {
        return <></>;
    }

    const currentFloorObj = floorPlans[selectedFloorIndex];

    const imageWidthPixels = currentFloorObj.imageWidth;
    const imageHeightPixels = currentFloorObj.imageHeight;
    const imageElementWidth = 3;
    const imageElementHeight= (imageHeightPixels / imageWidthPixels) * imageElementWidth;
    const minimapWidth = imageElementWidth + 0.18;
    const minimapHeight = imageElementHeight + 1.08;

    const hotSpotRadius = (imageElementHeight + imageElementWidth) / 40;

    const floorSwitcherHeight = -imageElementHeight / 2 - 0.3;

    const coneZeroPosition = -150;

    function calculateHotSpotPosition(x: number, y: number){
        // Since the zero point of element coordinate system is the middle, but zero point of image pixel system is the top-left corner,
        // need to calculate the distance from the middle for the given x and y.

        let distanceFromMiddleInPixelsX = x - imageWidthPixels / 2; // Distance from middle point can be found by subtracting the middle pixel from the x.
        let distanceFromMiddleInPixelsY = imageHeightPixels / 2 - y; // Reversed because zero point of image Y-axis is at the top.

        const meterPerPixelRatioX = imageElementWidth / imageWidthPixels;
        const meterPerPixelRatioY = imageElementHeight / imageHeightPixels;

        return new THREE.Vector3(distanceFromMiddleInPixelsX * meterPerPixelRatioX, distanceFromMiddleInPixelsY * meterPerPixelRatioY, 0.01);
    }

    return <Entity id="minimap" position="0 0 0.01"
                   geometry={`primitive: plane; width: ${minimapWidth}; height: ${minimapHeight};`}
                   material="color: black; shader: flat; opacity: 1; side: double;"
                   transparent="false"
    >
        <Entity primitive="a-image"
                id="minimapclosebutton"
                visible={!hideCloseButton}
                geometry="primitive: plane; height: 0.3; width: 0.3;"
                material="src: #closebutton; opacity: 1; color: white; side: double;"
                position={`${minimapWidth / 2 -0.3} ${minimapHeight / 2 -0.3} 0.02`}
                class="raycastable"
                events={{click: onClose}} />

        <Entity geometry={`primitive: plane; width: ${imageElementWidth - 0.2}; height: 0.02`} id="divider"
                material="color: black; shader: flat; opacity: 1; side: double;" position={`0 ${floorSwitcherHeight + 0.25} 0.02,`}
                transparent="false" />

        <Entity geometry={`primitive: plane; width: ${minimapWidth - 0.05}; height: ${minimapHeight - 0.05};`} id="background"
                material="color: white; shader: flat; opacity: 1; side: double;" position="0 0 0.01"
                transparent="false" />


        <Entity id="minimapImage" primitive="a-image" position="0 0 0.02"
                geometry={`height: ${imageElementHeight}; width: ${imageElementWidth}`}
                src={"#fp-" + currentFloorObj.name}
                transparent="false">

            {currentFloorObj.hotspots.map((hs) => <Entity id={"minimapHotSpot-" + hs.targetPanorama} key={"minimapHotSpot-" + hs.targetPanorama}
                                                       class="raycastable" primitive="a-image"
                                                       events={{click: () => {
                                                               launchPanoramaChangeAnimations(undefined, hs.targetCameraRotation);
                                                               removeHighlightHotSpot(hs.targetPanorama);

                                                               setTimeout(() => onHotSpotClick(hs.targetPanorama), 700);
                                                           },
                                                           // Show/hide the glow when mouse enters/leaves the hotspot.
                                                           mouseenter: function (event: CustomEvent) {
                                                               highlightHotSpot(hs.targetPanorama);
                                                           },
                                                           mouseleave: function (event: CustomEvent) {

                                                               // Hide glow only if not current spot.
                                                               if (currentPanorama !== hs.targetPanorama) {
                                                                   removeHighlightHotSpot(hs.targetPanorama);
                                                               }
                                                           }}}
                                                       position={calculateHotSpotPosition(hs.x, hs.y)}
                                                       geometry={{width: hotSpotRadius, height: hotSpotRadius, primitive: "plane"}}
                                                       material="src: #floorplanspotimage; alphaTest: 0.5"
                                                       transparent="false"
            >

                <Entity id={"minimapHotSpotGlow-" + hs.targetPanorama} key={"minimapHotSpotGlow-" + hs.targetPanorama}
                        primitive="a-image"
                        material={{src: "#floorplanspotglow", color: "blue",
                            opacity: currentPanorama === hs.targetPanorama ? 0.5 : 0}}
                        geometry={{primitive: "plane", width: hotSpotRadius * 2, height: hotSpotRadius * 2}}
                        position="0 0 -0.005"
                        transparent="true"
                        animation__show="property: material.opacity; type: number; easing: linear; from: 0; to: 0.5; dur: 400; startEvents: show"
                        animation__hide="property: material.opacity; type: number; easing: linear; from: 0.5; to: 0; dur: 400; startEvents: hide"
                />

                {currentPanorama === hs.targetPanorama && <Entity id="minimapViewCone" mixin="viewcone" geometry={`thetaStart: ${coneZeroPosition};`} />}
            </Entity> )}

        </Entity>

        <Entity geometry={`primitive: plane; width: ${imageElementWidth}; height: 0.4`} material="color: white"
                position={`0 ${-minimapHeight / 2 + 0.25} 0.001`} align="center" opacity="1">

            <Entity primitive="a-text" value={currentFloorObj.imageHeader} color="black" position={`0 0.05 0.01`} align="center" opacity="1" width={6}
                    id="minimapHeader" key="minimapHeader" />

            { (selectedFloorIndex > 0) && <Entity primitive="a-image" geometry="primitive: plane; height: 0.35; width: 0.35;" rotation="0 0 90"
                    material="src: #uiarrow; opacity: 1; color: black;"
                    position={`${-minimapWidth / 2 + 0.4} 0.03 0.01`}
                    class="raycastable"
                    events={{click: () => {
                            onSelectedFloorChanged(selectedFloorIndex - 1);
                        } }} />}

            { (selectedFloorIndex < floorPlans.length - 1) && <Entity primitive="a-image" geometry="primitive: plane; height: 0.35; width: 0.35;" rotation="0 0 -90"
                    material="src: #uiarrow; opacity: 1; color: black;"
                    position={`${minimapWidth / 2 - 0.2} 0.03 0.01`}
                    class="raycastable"
                    events={{click: () => {
                            onSelectedFloorChanged(selectedFloorIndex + 1);
                        } }} />}
        </Entity>
    </Entity>
}

function highlightHotSpot(targetPanorama: string) {

    const glow = document.querySelector("#minimapHotSpotGlow-" + targetPanorama);
    if (glow) {
        glow.emit("show", null, false);
    }
}

function removeHighlightHotSpot(targetPanorama: string) {
    const glow = document.querySelector("#minimapHotSpotGlow-" + targetPanorama);
    if (glow) {
        glow.emit("hide", null, false);
    }
}