import React, {useState, useEffect} from 'react';
import './App.css';
import {PanoramaScene} from "./PanoramaScene";
import {ApiClient, PanoramaTour} from "./api/apiClient";
import {BrowserUI} from "./components/ui/browser/BrowserUI";
import {Backdrop, CircularProgress, Stack, Typography} from "@mui/material";

export interface AppProps {
    contentUrl: string;
}

function App(props: AppProps) {

    const [panoramaTour, setPanoramaTour] = useState<PanoramaTour>();
    const [isVrMode, setIsVrMode] = useState(false);
    const [userSelectedPanorama, setUserSelectedPanorama] = useState<undefined | string>(undefined);
    const [loading, setLoading] = useState(true);

    const urlParams = new URLSearchParams(window.location.search);
    let defaultPanorama = urlParams.get('defaultsky');

    const apiClient = new ApiClient(props.contentUrl);

    useEffect(() => {
        apiClient.getPanoramaTour().then((result) => {
            if (result) {
                setPanoramaTour(result);
            }
        });
    }, []);

    const changePanorama = (newPanorama: string) => {
        console.log("Changing to: " + newPanorama);
        setUserSelectedPanorama(newPanorama);
    };

    const startPanorama = defaultPanorama && panoramaTour?.panoramas.some(p => p.name === defaultPanorama) ?
        defaultPanorama : panoramaTour?.panoramas[0]?.name;
    const currentPanorama = userSelectedPanorama ?? startPanorama;

    return <div style={{position: "absolute", height: "100%", width: "100%"}} id="root">
      { panoramaTour && currentPanorama && startPanorama && <>
          {!isVrMode && <BrowserUI floorPlans={panoramaTour.floorPlans} rooms={panoramaTour.rooms} changePanorama={changePanorama} currentPanorama={currentPanorama} />}

          <PanoramaScene tour={panoramaTour} currentPanorama={currentPanorama} startPanorama={startPanorama} changePanorama={changePanorama}
                         sceneLoaded={() => setLoading(false)}
                         setIsVrMode={setIsVrMode} isVrMode={isVrMode}
                    />

          <Backdrop
              open={loading}
              sx={{ bgcolor: 'rgba(255, 255, 255, 1)', color: '#fff', zIndex: 999999}}
          >
              <Stack direction={"row"} spacing={5} alignItems={"center"}>
                <CircularProgress size={100} /> <Typography variant={"h4"} color={"black"}>Starting tour...</Typography>
              </Stack>
          </Backdrop>
      </>
      }

      </div>
}

export default App;
