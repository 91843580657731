import {Entity, THREE} from "aframe";
import {Vector3} from "three";

export function launchPanoramaChangeAnimations(zoomTowards?: Vector3, rotation?: number) {
    const sky = document.querySelector('a-sky');
    const cameraRig = document.querySelector('#cameraRig');
    const scene = AFRAME.scenes[0];

    var spots = Array.prototype.slice.call(scene.querySelectorAll('.panoramaHotspot'));

    // Hide hotspots when animation starts
    spots.forEach(function (spot) {
        spot.object3D.visible = false;
    });

    // Emit the fade event.
    sky.emit("fade", null, false);

    if (rotation) {
        rotateCamera(rotation);
    }

    // If hotspot was clicked, zoom towards it.
    if (zoomTowards) {
        cameraRig.emit("zoomTowards", {zoomTo: zoomTowards}, false);
    }
}

export function rotateCamera(horizontalRotationDegrees: number) {

    if (horizontalRotationDegrees !== 0) {
        // Get rotation in degrees and convert to radians.
        const rotation = THREE.MathUtils.degToRad(horizontalRotationDegrees);

        // Get camera rig and camera
        const camera = document.querySelector('#camera');
        const cameraRig = document.querySelector('#cameraRig');

        // Since we cant rotate camera, need to rotate the rig. We must take into consideration the rotation of the camera,
        // so that the end result is rig rotation + camera rotation = wanted rotation.
        const newRotation = rotation - camera.object3D.rotation.y;

        // Rotate the camera.
        cameraRig.object3D.rotation.set(0, newRotation, 0);
    }
}

export function isVRMode() {
    const scene = document.querySelector("a-scene");

    return scene && scene.is('vr-mode');
}


// Move entity to the front of the camera.
export function bringToFront(entity: Entity) {

    const position = entity.object3D.position // the reference to our position
    const camera = entity.sceneEl!.camera // the reference to the THREE.Camera
    const front = new THREE.Vector3(0, 0, -1);
    camera.localToWorld(front);
    position.copy(front) // use it as the position

    entity.object3D.position.set(position.x, position.y, position.z);
}

export function startHouseTour() {
    document.querySelector('#cameraRig').emit("startTour", null, false);
}

export function exitFullscreen() {
    // Verify that canvas is fullscreen.
    const canvas = document.querySelector(".a-canvas");
    if (document.fullscreenElement === canvas) {
        // Exit fullscreen.
        document.exitFullscreen();
    }
}