import {THREE} from "aframe";

// 2D transformation matrix "matrix(a, b, c, d, e, f)" to degrees
function transformationToDegrees(transformation: string) {

    // Regex pattern to match the matrix values
    const regex = /matrix\(\s*([^\s,]+)\s*,\s*([^\s,]+)\s*,/;

    // Execute the regex on the matrix string
    const match = regex.exec(transformation);

    // Extract the first two values if match is successful
    if (match) {
        const a = parseFloat(match[1]);
        const b = parseFloat(match[2]);

        if (isNaN(a) || isNaN(b)) {
            return 0;
        }

        // Radians to degrees
        const angle = Math.atan2(b, a);
        return angle * (180 / Math.PI);
    } else {
        return 0;
    }
}

AFRAME.registerComponent('rotationreader', {
    schema: {
        isVrMode:  {type: 'boolean'},
        vrMinimapVisible: {type: 'boolean'}
    },
    update: function() {
        const isVrMode = this.data.isVrMode;

        console.log("camera init, vrmode: " + isVrMode);

        let zeroAngle = 0

        if (isVrMode && this.data.vrMinimapVisible) {
            const minimapViewCone = document.getElementById("minimapViewCone");
            if (minimapViewCone) {
                // @ts-ignore
                zeroAngle = AFRAME.utils.entity.getComponentProperty(minimapViewCone, 'geometry.thetaStart');
            }
        }

        if (!isVrMode) {
            const browserMinimapViewCone = document.getElementById("cone");

            if (browserMinimapViewCone) {
                const style = window.getComputedStyle(browserMinimapViewCone);
                const transform = style.getPropertyValue('transform');

                if (transform === 'none') {
                    return 0; // No rotation applied
                }

                const degrees = transformationToDegrees(transform);

                zeroAngle = -degrees;
            }
        }

        // @ts-ignore
        this.viewConeZeroAnle = zeroAngle
    },
    tick: function () {
        const isVrMode = this.data.isVrMode;

        // @ts-ignore
        const cameraViewPoint = THREE.MathUtils.radToDeg(this.el.parentEl.object3D.rotation.y + this.el.object3D.rotation.y);

        // @ts-ignore
        const newRotation = (this.viewConeZeroAnle ?? 0) + cameraViewPoint;

        if (isVrMode) {
            // @ts-ignore
            const minimapCone = this.el.parentEl.querySelector("#minimapViewCone");
            if (minimapCone) {
                // @ts-ignore
                AFRAME.utils.entity.setComponentProperty(minimapCone, 'geometry.thetaStart', newRotation);
            }
        }
        else {
            const browserMinimapCone = document.getElementById("cone");

            if (browserMinimapCone) {
                // @ts-ignore
                browserMinimapCone.style.transform = `rotate(${-newRotation}deg)`;
            }
        }
    }
});
