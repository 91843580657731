// @ts-ignore
import {Entity} from 'aframe-react';
import arrowDown from "../images/ui/arrow-down.png";
import arrowUp from "../images/ui/arrow-up.png";
import arrowDownLeft from "../images/ui/arrow-downleft.png";
import arrowDownRight from "../images/ui/arrow-downright.png";
import arrowUpLeft from "../images/ui/arrow-upleft.png";
import arrowUpRight from "../images/ui/arrow-upright.png";
import React from "react";
import {Floorplan, Panorama, PanoramaTour, RoomImage} from "../api/apiClient";
import vrimage from "../images/ui/vr.png";
import roomicon from "../images/ui/camera_indoor.svg";
import floorplanIcon from "../images/ui/layers.svg";
import floorplanspot from "../images/ui/floorplanspot.png";
import floorplanglow from "../images/ui/floorplanglow.png";
import closebutton from "../images/ui/close-button.png";
import uiarrow from "../images/ui/uiarrow.png";

export interface AssetsManagementProps {
    tour: PanoramaTour;
    startPanorama: string | null;
}

export function AssetsManagement(props: AssetsManagementProps) {
    const { tour, startPanorama } = props;

    // For some reason setting timeout for Aframe-React Entity primitive="a-assets" element didn't work. Basic AFrame a-assets does.
    // @ts-ignore  Assets are preloaded...
    return <a-assets id="asset-manager" timeout="3000">

        {   // Add all the panorama images as assets, so they can be used with the ID.
            // Sort the list so that the panorama which starts the tour is loaded first.
            tour.panoramas.sort((a, b) => a.name === startPanorama ? -1 : 1).map((panorama) => {
                return <img id={panorama.name} key={panorama.name} src={panorama.image} crossOrigin="anonymous" />;
            })
        }

        {   // Add all the floorplan images as assets, so they can be used with the ID.
            // fpindex query parameter is added to fix asset management issue with already loaded image (floor plans are loaded at floorPlanDataCalculator).
            tour.floorPlans.map((floorPlan, index) => {
                return <img id={"fp-" + floorPlan.name} key={"fp-" + floorPlan.name} src={floorPlan.image  + `?fpindex=${index}`} crossOrigin="anonymous" />;
            })
        }

        {   // Add all the room images as assets, so they can be used with the ID.
            // If panorama images are used as room images, do not add duplicates.
            (tour.usesPanoramasAsRoomImages ? [] : tour.rooms).map((room) => {
                return <img id={room.assetId} key={room.assetId} src={room.image} crossOrigin="anonymous" />;
            })
        }

        <img id="arrow-down" key="arrow-down" src={arrowDown} />
        <img id="arrow-up" key="arrow-up" src={arrowUp} />
        <img id="arrow-downleft" key="arrow-downleft" src={arrowDownLeft} />
        <img id="arrow-downright" key="arrow-downright" src={arrowDownRight} />
        <img id="arrow-upleft" key="arrow-upleft" src={arrowUpLeft} />
        <img id="arrow-upright" key="arrow-upright" src={arrowUpRight} />

        <img id="vrimage" key="vrimage" src={vrimage}/>
        <img id="roomicon" key="roomicon" src={roomicon}/>
        <img id="floorplanIcon" key="floorplanIcon" src={floorplanIcon}/>
        <img id="floorplanspotimage" key="floorplanspotimage" src={floorplanspot} />;
        <img id="floorplanspotglow" key="spotglow" src={floorplanglow} />;
        <img id="closebutton" key="closebutton" src={closebutton} />;
        <img id="uiarrow" key="uiarrow" src={uiarrow} />;

        <Entity primitive="a-mixin" id="arrow" material={`opacity: 0.7; height: 0.75; width: 1.5; side: double;`} look-at="#camera" />
        <Entity primitive="a-mixin" id="down" material="src: #arrow-down" />
        <Entity primitive="a-mixin" id="up" material="src: #arrow-up" />
        <Entity primitive="a-mixin" id="upright" material="src: #arrow-upright" />
        <Entity primitive="a-mixin" id="upleft" material="src: #arrow-upleft" />
        <Entity primitive="a-mixin" id="downright" material="src: #arrow-downright" />
        <Entity primitive="a-mixin" id="downleft" material="src: #arrow-downleft" />

        <Entity primitive="a-mixin" id="arrowglow" rotation="0 0 0" geometry="primitive: plane; height: 2; width: 2" />
        <Entity primitive="a-mixin" id="spotglow" rotation="90 0 0" geometry="primitive: plane; height: 2; width: 2" />

        <Entity primitive="a-mixin" id="menubutton" geometry="primitive: circle; radius: 0.6; segments: 128;"
                material="flatShading: true; color: white; side: double; opacity: 1;transparent: false;"
        />
        <Entity primitive="a-mixin" id="menubuttonborder"
                position="0 0 -0.001"
                geometry="primitive: circle; radius: 0.63; segments: 128;"
                material="flatShading: true; color: black; side: double; opacity: 1;transparent: false;"
        />

        <Entity primitive="a-mixin" id="controllerinstruction"
                geometry="primitive: circle; radius: 0.012; segments: 64;" rotation="-110 0 0"
                material="flatShading: true; color: white; side: double; opacity: 1;" />

        <Entity primitive="a-mixin"
                id="imagemenu_frame"
                geometry="primitive: plane;"
                material="color: black; shader: flat; opacity: 1"
                animation__scale="property: scale; to: 1.2 1.2 1.2; dur: 200; startEvents: mouseenter"
                animation__scale_reverse="property: scale; to: 1 1 1; dur: 200; startEvents: mouseleave"
        />

        <Entity primitive="a-mixin"
                id="imagemenu_image"
                geometry="primitive: plane; height:0.3"
                material="color: white; shader: flat"
                position="0 0 0.005"
        />

        <Entity primitive="a-mixin"
                id="viewcone"
                geometry="primitive: circle; radius: 0.5; thetaLength: 130"
                position="0 0 0.02"
                material="color: blue; shader: flat; opacity: 0.5;"
        />


        {/* @ts-ignore */}
    </a-assets>
}