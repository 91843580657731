// THIS CODE IS FROM aframe-screen-display COMPONENT. https://github.com/diarmidmackenzie/screen-display
// The component didn't support the fov we want our viewer to display.
// Had to do a dirty fix to use the component.

import {  } from "aframe";

/* xpos & ypos approximately like CSS px
 * zdist is units in m.  Dfeault is 1cm.  Don't set below 0.5cm,
 * or you will hit the default near clipping plane on the camera.
 * (of course you can change that if you want...)
 * near	Camera frustum near clipping plane.	0.005
 * xscale - when default is 1,
 * object is scaled so that 1m takes up about 1cm on screen, 1:100 scale */
AFRAME.registerComponent('screen-layout', {

    schema: {
        position: {type: 'string', default: "percent"},
        xpos: {type: 'number', default: 50},
        ypos: {type: 'number', default: 50},
        scale: {type: 'string', default: "percent"},
        keepaspect: {type: 'boolean', default: true},
        width: {type: 'number', default: 10},
        height: {type: 'number'},
        zscale: {type: 'number'},
        zdist: {type: 'number', default: 0.01},
    },

    update: function() {
        // Get screen size
        // See: https://www.w3schools.com/jsref/prop_win_innerheight.asp



        const screenwidth = window.innerWidth ||
            document.documentElement.clientWidth ||
            document.body.clientWidth;

        const screenheight = window.innerHeight ||
            document.documentElement.clientHeight ||
            document.body.clientHeight;

        let xpospercent: number = 0;
        let ypospercent: number = 0;

        let xscalepercent: number = 0;
        let yscalepercent: number = 0;

        // Set up position, based on configuration.
        switch (this.data.position) {
            case "percent":
                xpospercent = this.data.xpos;
                ypospercent = this.data.ypos;
                break;

            case "pixels":
                xpospercent = 100 * this.data.xpos / screenwidth;
                ypospercent = 100 * this.data.ypos / screenheight;
                break;

            default:
                console.log(`Unexpected Config: ${this.data.position}`);

        }

        // Set up scale, based on configuration.
        // Swt up height & width first.
        // Then rewrite height if we need to preserve aspect ratio.
        switch (this.data.scale) {
            case "percent":
                xscalepercent = this.data.width;
                yscalepercent = this.data.height;
                break;

            case "pixels":
                xscalepercent = 100 * this.data.width / screenwidth;
                yscalepercent = 100 * this.data.height / screenheight;
                break;

            default:
                console.warn(`Unexpected Config: ${this.data.position}`);
        }

        if (this.data.keepaspect) {
            // keep aspect ratio.
            // Set height based on width.
            yscalepercent = xscalepercent * screenwidth / screenheight;

            if (this.data.height) {
                console.warn(`Height being ignored, since "keepaspect" is set.`)
            }
        }
        else
        {
            if (!this.data.height) {
                console.warn(`"keepaspect" is not set, so height is required, but none provided.`)
            }
        }

        // CHANGED FOV CODE
        // height of FOV at zdist in meters.
        // FOV is based on height, not width:
        // https://threejs.org/docs/#api/en/cameras/PerspectiveCamera.fov
        // No idea why, but true default FOV seems to be 59 degrees.
        // Default FOV is supposed to be 80.
        // VR image viewer wants to use FOV 60, so true fov is 44.
        var fovHeight = Math.tan(44  * Math.PI / 180) * this.data.zdist;
        var fovWidth = fovHeight * screenwidth / screenheight;

        // Now x position is (relPx from Center) / (width in Px) * fovWidth.
        // Similarly for y...
        //var x3DPos = xRelPx / this.width * fovWidth;
        //var y3DPos = yRelPx / this.height * fovHeight;
        var x3DPos = ((xpospercent / 100) - 0.5) * fovWidth;
        var y3DPos = (0.5 - (ypospercent / 100)) * fovHeight;

        this.el.object3D.position.set(x3DPos, y3DPos, -this.data.zdist)

        const x3DScale = fovWidth * xscalepercent / 100
        const y3DScale = fovHeight * yscalepercent / 100

        // zscale value depends whether keepaspect is set.
        // if it is not set, zscale parameter provides the percentage of the z-distance
        // to be used as the depth of the object.
        var z3DScale = this.data.zscale * this.data.zdist / 100;
        if (this.data.keepaspect) {
            z3DScale = x3DScale;
        }
        else if (!this.data.zscale) {
            console.warn(`"keepaspect" is not set, so zscale is required, but none provided.`)
        }

        this.el.object3D.scale.set(x3DScale, y3DScale, z3DScale)

        // @ts-ignore
        this.ScreenWidth = screenwidth;
        // @ts-ignore
        this.ScreenHeight = screenheight;
    },

    tick: function() {

        const screenwidthNow = window.innerWidth ||
            document.documentElement.clientWidth ||
            document.body.clientWidth;

        const screenheightNow = window.innerHeight ||
            document.documentElement.clientHeight ||
            document.body.clientHeight;

        // @ts-ignore
        if ((this.ScreenWidth !== screenwidthNow) || (this.ScreenHeight !== screenheightNow)) {
            this.update()
        }
    }

});