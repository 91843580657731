import {Floorplan, FloorplanHotspot, UnrealFloorPlan, UnrealViewPoint} from "./apiClient";


export async function buildFloorPlanData(floorPlan: UnrealFloorPlan, viewPoints: UnrealViewPoint[], contentUrl: string,
                                         imageFolder: string | undefined, imageExtension: string | undefined): Promise<Floorplan> {

    const imageUrl = `${contentUrl}/${imageFolder ? imageFolder + "/" : "floors/"}${floorPlan.Name}.${imageExtension ?? "jpg"}`;

    const dimensions = await getImageDimensions(imageUrl);

    const imageEdges = calculateImageEdges(floorPlan, dimensions.height, dimensions.width)

    // View points in this floor.
    const currentFloorViewPoints = viewPoints.filter((vp) => vp.Floor === floorPlan.Name);

    // Build the hotspots
    const hotSpots = currentFloorViewPoints.reduce<FloorplanHotspot[]>((hotspots: FloorplanHotspot[], vp: UnrealViewPoint) =>
        {
            const hotSpotPosition = calculateHotSpotImagePosition(vp, imageEdges);

            // Do not add hotspot if viewpoint out of floor image bounds.
            if (hotSpotPosition) {
                hotspots.push({
                    ...hotSpotPosition,
                    targetPanorama: vp.Name.replace(" ", "_"),
                    targetCameraRotation: vp.LookAtDirection.X
                });
            }

            return hotspots;
        }, []);

    return {
        name: floorPlan.Name,
        image: imageUrl,
        imageHeader: floorPlan.Name,
        imageHeight: dimensions.height,
        imageWidth: dimensions.width,
        hotspots: hotSpots
    }

}

interface imageEdges {
    xZero: number;
    xMax: number;
    yZero: number;
    yMax: number;
    pixelRatio: number;
}

// Calculate the limits of the floorplan image in 3D world.
export function calculateImageEdges(floorPlan: UnrealFloorPlan, imageHeight: number, imageWidth: number): imageEdges {
    const xZero = (floorPlan.ViewCenter.X - (floorPlan.ViewWidth/2));
    const xMax = floorPlan.ViewCenter.X+(floorPlan.ViewWidth/2);

    const yZero = floorPlan.ViewCenter.Y-(imageHeight/imageWidth)*floorPlan.ViewWidth/2;

    const yMax = floorPlan.ViewCenter.Y+(imageHeight/imageWidth)*floorPlan.ViewWidth/2;

    return {
        xZero: xZero,
        xMax: xMax,
        yZero: yZero,
        yMax: yMax,
        pixelRatio: imageWidth / floorPlan.ViewWidth
    }
}

// Calculate position of a viewpoint in pixels of the floorplan image.
export function calculateHotSpotImagePosition(viewPoint: UnrealViewPoint, imageDimensions: imageEdges) {

    // Validate that the hotspot is in the area of the floorplan image.
    if (viewPoint.Location.X < imageDimensions.xZero ||
        viewPoint.Location.X > imageDimensions.xMax ||
        viewPoint.Location.Y < imageDimensions.yZero ||
        viewPoint.Location.Y > imageDimensions.yMax
    )
    {
        return undefined;
    }

    // Calculate pixel position
    const imagePosX = (viewPoint.Location.X - imageDimensions.xZero) * imageDimensions.pixelRatio;
    const imagePosY = (viewPoint.Location.Y - imageDimensions.yZero) * imageDimensions.pixelRatio;

    return {x: imagePosX, y: imagePosY};
}

// Fetch the dimensions of the image
function getImageDimensions(url: string): Promise<{ width: number; height: number }> {
    return new Promise((resolve, reject) => {
        const img = new Image();

        img.onload = () => {
            resolve({
                width: img.width,
                height: img.height,
            });
        };

        img.onerror = (error) => {
            reject(error);
        };

        img.src = url;
    });
}