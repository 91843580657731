import {Fab, Tooltip} from "@mui/material";
import React, {ReactElement} from "react";

export interface BrowserMenuButtonProps {
    tooltip?: string;
    onClick?: () => void;
    icon: ReactElement;
    className?: string;
    id?: string;
}

export function BrowserMenuButton(props: BrowserMenuButtonProps) {
    const {tooltip, icon, onClick, className, id} = props;

    const largeIcon = React.cloneElement(icon, {fontSize: "large"});

    return <Tooltip title={tooltip}>
        <Fab id={id} className={className} color="primary" size={"large"} aria-label="fullscreen" onClick={onClick}
             sx={{
                 ":hover":{
                     backgroundColor:"primary.light"
                 }
             }}
        >
            {largeIcon}
        </Fab>
    </Tooltip>
}