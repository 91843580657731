import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';

const rootElement = document.getElementById('root') as HTMLElement;

const root = ReactDOM.createRoot(rootElement);

const contentBaseUrl = rootElement.getAttribute('contentBaseUrl');

let builderParam = rootElement.getAttribute('builder');
let modelParam = rootElement.getAttribute('model');
const sp = new URLSearchParams(window.location.search);
const builder = sp.get("b");
if (builder) {
    builderParam = builder;
}
const model = sp.get("m");
if (model) {
    modelParam = model;
}

const contentUrl = contentBaseUrl + `/builders/${builderParam}/VRTours/${modelParam}`;

root.render(
  <React.StrictMode>
    <App contentUrl={contentUrl} />
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
