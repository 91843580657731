import {Floorplan} from "../../../api/apiClient";
import {useState} from "react";
import {Box, CircularProgress, IconButton, Paper, Stack, Typography, useMediaQuery, useTheme} from "@mui/material";
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import {BrowserFloorMap} from "./BrowserFloorMap";
import {Close} from "@mui/icons-material";


export interface BrowserMinimapProps {
    floorPlans: Floorplan[];
    currentPanorama: string;
    changePanorama: (panorama: string) => void;
    hideMinimap: () => void;
}



export function BrowserMinimap(props: BrowserMinimapProps) {
    const {currentPanorama, changePanorama, floorPlans, hideMinimap} = props;

    const [selectedFloorIndex, setSelectedFloorIndex] = useState(0);
    const [prevPanorama, setPrevPanorama] = useState("");
    const [isFloorPlanImageLoading, setIsFloorPlanImageLoading] = useState(true);

    const theme = useTheme();
    const isLargeScreen = useMediaQuery(theme.breakpoints.up('lg'));
    const isVerySmallScreen = useMediaQuery(theme.breakpoints.down('sm'));

    const screenVerticallyLarge = isLargeScreen || isVerySmallScreen;


    const handleFloorPlanChange = (newFloorPlanIndex: number) => {
        setSelectedFloorIndex(newFloorPlanIndex);
        setIsFloorPlanImageLoading(true);
    }

    // Possible floor change when panorama changes
    if (currentPanorama !== prevPanorama) {
        const foundFloorPlanIndex = findCurrentFloorIndex();
        if (foundFloorPlanIndex >= 0 && selectedFloorIndex !== foundFloorPlanIndex) {
            handleFloorPlanChange(foundFloorPlanIndex);
        }

        setPrevPanorama(currentPanorama);
    }

    const currentFloorPlan = floorPlans[selectedFloorIndex];

    if (!currentFloorPlan) {
        return <></>;
    }

    // If aspect ratio of window is smaller than that of the floor plan image, we should limit image width instead of height.
    // That's because the height limit won't take effect in that case, which causes the hotspot position calculation to be wrong because it is done based on the containing div size,
    // but image would be forced to shrink to fit the screen.
    const limitImageHeight = (window.innerWidth/window.innerHeight) > (currentFloorPlan.imageWidth/currentFloorPlan.imageHeight);

    function findCurrentFloorIndex() {
        return floorPlans.findIndex((fp) =>
            fp.hotspots.some((hp) => hp.targetPanorama === currentPanorama))
    }


    return <div style={{zIndex: 1050, position:"relative", textAlign: "center", flexShrink: 0}}>
        <Paper elevation={3}
               sx={{
                   p: 2,
                   paddingTop: screenVerticallyLarge ? 0 : 2,
                   marginTop: isVerySmallScreen ? 5 : 0,
                   height: limitImageHeight ? (screenVerticallyLarge ? "80vh" : "87vh") : undefined,
                   width: !limitImageHeight ? "80%" : undefined,
                   position: "relative",
                   display: "inline-block"
               }}
               className={"fade-in"}
        >
            {/*Close minimap button*/}
            {screenVerticallyLarge && <div style={{textAlign: "right", flexShrink: 0, height: '10%'}}>
                <IconButton size={"large"} onClick={hideMinimap}>
                    <Close fontSize={"inherit"}/>
                </IconButton>
            </div>}

            {/*Floorplan image*/}
            <div style={{
                height: screenVerticallyLarge ? "80%" : "90%",
                position: "relative",
                border: `5px solid ${theme.palette.primary.main}`,
                opacity: isFloorPlanImageLoading ? 0.5 : 1
            }}>
                <BrowserFloorMap changePanorama={changePanorama} floorPlan={currentFloorPlan}
                                 currentPanorama={currentPanorama} onFloorPlanImageLoaded={() => setIsFloorPlanImageLoading(false)} />

                {/*Loading symbol*/}
                {isFloorPlanImageLoading && <div style={{
                    position: "relative",
                    bottom: "65%",
                    left: "0%",
                    zIndex: 30,
                    display: "block"
                }}>
                    <CircularProgress size={"50%"} sx={{}} />
                </div>}
            </div>

            {/*Floor switch buttons*/}
            <div style={{flexShrink: 0, height: '10%',  margin: "auto"}}>
                <Stack direction={"row"} justifyContent={"space-between"} sx={{alignItems: "center"}}>
                    <IconButton size={"large"}
                                disabled={selectedFloorIndex <= 0}
                                onClick={() => handleFloorPlanChange(selectedFloorIndex - 1)}>
                        <ArrowBackIosIcon fontSize={"inherit"}/>
                    </IconButton>

                    <Typography
                        variant={screenVerticallyLarge ? "h4" : "h5"}>{currentFloorPlan.imageHeader}</Typography>

                    <IconButton size={"large"}
                                disabled={selectedFloorIndex >= floorPlans.length - 1}
                                onClick={() => handleFloorPlanChange(selectedFloorIndex + 1)}>
                        <ArrowForwardIosIcon fontSize={"inherit"}/>
                    </IconButton>
                </Stack>
            </div>
        </Paper>
    </div>
}