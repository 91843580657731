import anime from "animejs"

AFRAME.registerComponent("camerazoom", {
    init: function() {

        // @ts-ignore
        this.el.sceneEl.addEventListener('camerazooming', this.handleZoom.bind(this));
    },
    handleZoom: function (event: CustomEvent) {

        let zoomerPosition = event.detail.zoomTo;
        let animX = zoomerPosition.x > 0 ? zoomerPosition.x + 20 : zoomerPosition.x - 20;
        let animZ = zoomerPosition.z > 0 ? zoomerPosition.z + 20 : zoomerPosition.z - 20;

        const animPosition = animX + " 0 " + animZ;
        const camera = this.el;

        anime({
                targets: camera,
                position: ["0 0 0", animPosition],
                duration: 700,
                easing: 'easeInSine',
                complete: function () {
                    anime({
                        targets: camera,
                        position: [animPosition, "0 0 0"],
                        duration: 1,
                        easing: 'easeInSine'
                    });
                }
            });
    }

});
