import {Entity, THREE} from "aframe";
import _ from "lodash";

AFRAME.registerComponent("imagemenulayout", {
    schema: {
        margin: {default: 1, type: 'number'},
        align: {default: 'center', oneOf: ['left', 'center']},
        itemsPerRow: { default: undefined, type: 'int'},
        rowHeight: { default: undefined, type: 'number'}
    },
    init: function () {
        const { itemsPerRow, margin, rowHeight, align } = this.data;

        // @ts-ignore Get child entities. They are images.
        let children = this.el.getChildEntities();

        let startingPosition = AFRAME.utils.entity.getComponentProperty(this.el, "position");

        // Solve positions of images.
        let newPositions = getBoxPositions(startingPosition, children, itemsPerRow, margin, rowHeight, align);

        // Set the positions of images.
        children.forEach((childEl: Entity, index: number) => {
            let newPosition = newPositions[index];
            childEl.object3D.position.set(newPosition[0].x, newPosition[0].y, newPosition[0].z);
        })
    }
});


function getBoxPositions(startPosition: THREE.Vector3, items: Entity[], itemsPerRow: number | undefined, margin: number, rowHeight: number, align: string) {

    let returnPositions: [position: THREE.Vector3, width: number][] = [];
    let numberOfItems = items.length;
    let positionHelper = new THREE.Vector3(startPosition.x, startPosition.y, startPosition.z); // Starting point is the position of the parent element.

    let rowIndex = 0; // How many images in a row.
    let rowCount = 0; // How many rows.

    let columnIndex; // How many images.
    for (columnIndex = 0; columnIndex < numberOfItems; columnIndex++) {

        // Get width of the image.
        let columnWidth = AFRAME.utils.entity.getComponentProperty(items[columnIndex], "geometry.width");

        // We have reached maximum amount of images in a row.
        if (rowIndex === itemsPerRow) {
            rowIndex = 0;
            rowCount++;
        }

        // First image of a row.
        if (rowIndex === 0) {
            // Solve Y position of the row. First row is at starting point. The rest are above it.
            let rowPositionY = (startPosition.y - ((rowHeight + margin) * rowCount));

            // Save the position. For the first image, the X position is always of the starting point.
            returnPositions[columnIndex] = [new THREE.Vector3(startPosition.x, rowPositionY, startPosition.z), columnWidth];

            // Set the helper to the first position that allows new images.
            positionHelper.x = startPosition.x + (columnWidth / 2) + margin;
            positionHelper.y = rowPositionY;
        }
        else {
            let columnPositionX = positionHelper.x + (columnWidth / 2);
            // Save the position. X position is half of the image width, because the zero position is in the middle of the image.
            returnPositions[columnIndex] = [new THREE.Vector3(columnPositionX, positionHelper.y, startPosition.z), columnWidth];

            positionHelper.x += columnWidth + margin;
        }

        rowIndex++;
    }

    // Images are now loosely lined up to left. Need to move them to line up properly.
    if (align) {

        // Group to rows of images.
        let layoutRows = _.groupBy(returnPositions,(vector: [THREE.Vector3, number]) => vector[0].y);

        _.forEach(layoutRows,(row: [THREE.Vector3, number][]) => {

            // let widthOfRow = 0;
            // row.forEach((column: [Vector3, number]) => {
            //     widthOfRow += column[1] + margin;
            // });
            let xChange: number;

            switch (align) {
                case 'left':
                    // Images are already lined up to the left. But the rows are lined up so that the middle points of the first images are lined up.
                    // To make the left side of the first images line up, subtract half of the width of the first image of each row from all images X positions.
                    let width = row[0][1];
                    xChange = -1 * width / 2;
                    break;
                case 'center':
                    // To center the images we move all the images to the left by half of the row width.
                    // Subtract half of the width of the row from all images X positions.
                    let widthOfRow = row[row.length - 1][0].x - startPosition.x;
                    xChange = widthOfRow / 2;
                    break;
            }

            // Execute moving.
            row.forEach((item: [THREE.Vector3, number]) => {
                item[0].x -= xChange;
            });
        });
    }

    return returnPositions;
}