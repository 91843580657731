import {Entity, THREE} from "aframe";
import {bringToFront, isVRMode} from "../../common";

AFRAME.registerComponent("menucontainer", {
    schema: {},
    init: function () {
    },
    events: {
        loaded: function() {
            this.el.emit("openmenu", null, false);

            if (isVRMode()) {
                bringToFront(this.el);
            }
        }
    }
});